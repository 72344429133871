import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

const Main = styled.main`
  background: #58aedd;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .privacyContainer {
    background: #fff;
    padding: 0 5vw;
  }
`;

const PrivacyPage = () => {
  return (
    <Main>
      <h1>Bloinka! - Stay Social</h1>

      <div className="privacyContainer">
        <h1>Privacy Policy</h1>

        <p>Last updated: 1/1/2023</p>

        <h2>Introduction</h2>

        <p>
          Welcome to bloinka.com ("we," "our," or "us"). We are committed to
          protecting your privacy and ensuring the security of your personal
          information. This Privacy Policy explains how we collect, use, and
          safeguard your data, including information related to SMS
          communications.
        </p>

        <p>
          By using our website and services, you consent to the practices
          described in this Privacy Policy.
        </p>

        <h2>Information We Collect</h2>

        <h3>1. Personal Information</h3>

        <p>We may collect the following types of personal information:</p>

        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Any other information you provide to us</li>
        </ul>

        <h3>2. SMS Information</h3>

        <p>
          When you opt-in to receive SMS notifications or updates from us, we
          may collect and store the following information:
        </p>

        <ul>
          <li>Phone number</li>
          <li>SMS content</li>
          <li>Date and time of SMS</li>
          <li>Your consent to receive SMS</li>
        </ul>

        <h2>How We Use Your Information</h2>

        <p>We use the collected information for the following purposes:</p>

        <ol>
          <li>
            <strong>Providing Services:</strong> To provide you with the
            services you requested, including sending SMS notifications or
            updates.
          </li>
          <li>
            <strong>Communications:</strong> To communicate with you, respond to
            your inquiries, and send you important updates.
          </li>
          <li>
            <strong>Marketing:</strong> To send you promotional and marketing
            materials with your consent.
          </li>
          <li>
            <strong>Legal Requirements:</strong> To comply with legal
            obligations, such as tax and regulatory requirements.
          </li>
        </ol>

        <h2>SMS Safeguards</h2>

        <p>
          We take the following steps to safeguard the SMS information we
          collect:
        </p>

        <ol>
          <li>
            <strong>Secure Storage:</strong> We store SMS information in a
            secure manner to protect it from unauthorized access.
          </li>
          <li>
            <strong>Limited Access:</strong> Only authorized personnel have
            access to your SMS information, and access is restricted to what is
            necessary for business purposes.
          </li>
          <li>
            <strong>Data Encryption:</strong> We use encryption and other
            security measures to protect the transmission of SMS data.
          </li>
          <li>
            <strong>User Consent:</strong> We obtain your explicit consent
            before sending SMS notifications or updates.
          </li>
        </ol>

        <h2>Sharing Your Information</h2>

        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except as described in this
          Privacy Policy.
        </p>

        <p>We may share your information with:</p>

        <ul>
          <li>Service providers who assist us in providing services to you.</li>
          <li>
            Legal authorities if required by law or to protect our rights,
            privacy, safety, or property.
          </li>
        </ul>

        <h2>Your Choices</h2>

        <p>You have the following choices regarding your information:</p>

        <ul>
          <li>
            You can opt-out of receiving SMS notifications at any time by
            contacting us.
          </li>
          <li>
            You can update or correct your personal information by contacting
            us.
          </li>
        </ul>

        <h2>Children's Privacy</h2>

        <p>
          Our services are not intended for children under 13 years of age. We
          do not knowingly collect personal information from children under 13.
          If you believe that we have collected information from a child under
          13, please contact us immediately.
        </p>

        <h2>Changes to this Privacy Policy</h2>

        <p>
          We may update this Privacy Policy to reflect changes in our practices
          or for other operational, legal, or regulatory reasons. We will notify
          you of any material changes by posting the updated policy on our
          website.
        </p>

        <h2>Contact Us</h2>

        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>

        <p>Greg Tyndall - greg@bloinka.com</p>
      </div>
    </Main>
  );
};

export default PrivacyPage;

export const Head: HeadFC = () => <title>Bloinka! - SMS</title>;
